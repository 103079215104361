html,
      body,
      header,
      .intro-2 {
        height: 760px;
      }
      #companyName {
          display: none;
        }
  
      @media (max-width: 740px) {
  
        html,
        body,
        header,
        .intro-2 {
          height: 760px;
        }
      }
  
      @media (min-width: 800px) and (max-width: 850px) {
  
        html,
        body,
        header,
        .intro-2 {
          height: 760px;
        }
        
      }
  